$mq-breakpoints: (
  mobile: 320px,
  tablet: 768px,
  desktop: 992px,
  wide: 1200px
);
$ContentBackgroundColor: white;
$modificationInsertedLineColor: #D4FCBC;
$modificationDeletedLineColor: #FBB;


:root {
  // NEW COLORS
  --total-blue-600: #285AFF;
  --total-red-600: #ED0000;
  --total-white-600: #FFFFFF;
  --total-light-gray: #F1F5F6;
  --total-gray: #374649;
  --total-light: #F8F9FA;
  --total-dark: #707173;

  // Fonts

  --total-font-nunito-extra-light:     "Nunito Extra-light",sans-serif;
  --total-font-nunito-light:     "Nunito Light",sans-serif;
  --total-font-nunito-regular: "Nunito Regular",sans-serif;
  --total-font-nunito-semi-bold:     "Nunito SemiBold",sans-serif;
  --total-font-nunito-bold:     "Nunito Bold",sans-serif;
  --total-font-nunito-extra-bold:     "Nunito ExtraBold",sans-serif;
  --total-font-nunito-black:     "Nunito Black",sans-serif;

  --total-font-roboto-thin:     "Roboto Thin",sans-serif;
  --total-font-roboto-light:    "Roboto Light",sans-serif;
  --total-font-roboto-regular:  "Roboto Regular",sans-serif;
  --total-font-roboto-medium:   "Roboto Medium",sans-serif;
  --total-font-roboto-bold:     "Roboto Bold",sans-serif;
  --total-font-roboto-black:    "Roboto Black",sans-serif;
}
