@import '~sass-mq/mq';
@import 'variables';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/badge";

@import 'helpers';
@import 'material_icons';
@import 'mixins/custom-mat-table';
@import "mixins/custom-font";

.coaching__title{
  color: var(--total-red-600);
  font-family: var(--total-font-roboto-regular);
  font-size: 38px;
  letter-spacing: 0;
  line-height: 48px;
  padding-top: 10px;
}
.coaching__subTitle{
  color: var(--total-dark);
  font-family: var(--total-font-roboto-regular);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.cdk-overlay-pane {
  max-width: 91vw !important; // default: 80vw
}

.mat-button-toggle-checked{
  background-color: var(--total-red-600)!important;
  color: white!important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
  @include mq($until: tablet) {
    padding: 0 10px!important;
    font-size: 0.7rem!important;
  }
}

// Tabs
.mat-tab-group {

  &.small-header {
    .mat-tab-label {
      height: 36px;
    }
  }
}
