/* You can add global styles to this file, and also import other style files */

@import 'app/scss/app';
html,
body {
    height: 100%;
    scroll-behavior: smooth !important;
    touch-action: pan-y;
}

button.mat-stroked-button,
.mat-raised-button,
.mat-button {
    border-radius: 0 !important;
    background-color: var(--total-red-600);
}

.mat-expansion-indicator::after {
    color: var(--total-red-600);
}

.mat-expansion-panel {
    border-radius: 20px!important;
    box-shadow: unset!important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    padding-left: 3vw !important;
}